import React from "react";
import TopAppBarEn from "../en/TopAppBar";
function AboutEn() {
    return (
      <>
      <TopAppBarEn></TopAppBarEn>
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2><b> About Us</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                  <p>
                  <a className="text-blue-400 hover:text-blue-600" href="https://arfusoft.com/" target="_blank" rel="noreferrer"> Arfusoft </a>
                  is the software company that built this website.
                    <span> Arfusoft has years of experience in developing:</span>
                  </p>
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        Web
                        <code className="text-sm font-bold "> frontend</code> applications
                      </p>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        Web
                        <code className="text-sm font-bold "> backend/server</code> applications
                      </p>
                    </li>
                                <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold ">Mobile</code> applications for Android and IOS
                      </p>
                    </li>
                                            <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold ">Desktop</code> applications for Win and MAC
                      </p>
                    </li>
                  </ul>
                  <p> A website or an application will boost your business. 
                    In the end, you get more visibility and more revenue. 
                    We can develop the best software solution for your business. 
                    We are also open to any collaboration. So don't hesitate to contact us at 
                     <a className="text-blue-400 hover:text-blue-600" href="mailto:contact@arfusoft.com"> contact@arfusoft.com </a> or visit our official website <a className="text-blue-400 hover:text-blue-600" href="https://arfusoft.com/" target="_blank" rel="noreferrer">here</a> !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default AboutEn;