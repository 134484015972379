import { BrowserRouter , Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import HomeEn from "./components/en/HomeEn";
import HomeFr from "./components/fr/HomeFr";
import NotFound from "./components/NotFound";
import AboutEn from "./components/en/AboutEn";
import AboutFr from "./components/fr/AboutFr";
import EnDreams from "./components/en/EnDreams";
import FrDreams from "./components/fr/FrDreams";
import HoroscopeEnMain from "./components/en/HoroscopeEnMain";
import HoroscopeFrMain from "./components/fr/HoroscopeFrMain";
import FrHoroscope from "./components/fr/FrHoroscope";
import EnHoroscope from "./components/en/EnHoroscope";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/" element = { <Layout></Layout> }>
            <Route index element = { <HomeEn></HomeEn> }></Route>
            <Route path ="/dreams/:category" element = { <EnDreams/> } ></Route>
            <Route path ="/horoscope/en" element = { <HoroscopeEnMain/> } ></Route>
            <Route path ="/horoscope/en/:sign" element = { <EnHoroscope/> } ></Route>
            <Route path = "/about" element = { <AboutEn></AboutEn> } ></Route>

            <Route path ="/fr" element = { <HomeFr/> } ></Route>
            <Route path ="/reves/:category" element = { <FrDreams/> } ></Route>
            <Route path ="/horoscope/fr" element = { <HoroscopeFrMain/> } ></Route>
            <Route path ="/horoscope/fr/:signe" element = { <FrHoroscope/> } ></Route>
            <Route path = "/a-propos" element = { <AboutFr></AboutFr> } ></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App

