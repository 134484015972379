import React from "react";
import TopAppBarFr from "../fr/TopAppBarFr";
function AboutFr() {
    return (
      <>
      <TopAppBarFr></TopAppBarFr>
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2><b> A propos</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                  <p>
                 <a className="text-blue-400 hover:text-blue-600" href="https://arfusoft.com/" target="_blank" rel="noreferrer"> Arfusoft </a>
                  est la société de development d'applications ayant conçu ce site web. 
                    <span> Notre société a des années d'expérience dans la conception et l'implementation d': </span>
                  </p>
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold "> Interfaces utilisateurs</code> web 
                      </p>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold "> Applications server </code> 
                      </p>
                    </li>
                                <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold ">Applications Mobiles</code> 
                      </p>
                    </li>
                                            <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold ">Applications de bureau </code>
                      </p>
                    </li>
                  </ul>
                  <p> Un site web ou une application web peut et va certainement vous aider à booster votre entreprise parcequ'elle vous permet
                    d'être plus visible et donc d'avoir plus de clients et de profit. 
                   Quelque soit votre idée d'application, Arfusoft peut vous aider à la réaliser. 
                    Nous sommes joignable par email à 
                     <a className="text-blue-400 hover:text-blue-600" href="mailto:contact@arfusoft.com"> contact@arfusoft.com </a> . Notre site web officiel se trouve  <a className="text-blue-400 hover:text-blue-600" href="https://arfusoft.com/" target="_blank" rel="noreferrer">içi</a> !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default AboutFr;