import {React, useState, useEffect} from "react";
import HomeFooterFr from "./HomeFooterFr";
import engineer from "../../assets/images/mystic6.avif";
import TopAppBarFr from "./TopAppBarFr";



function LazyBackgroundImageKnowledge({img}) {
  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => {
    setLoaded(true);
  };
return (
 <div className="h-full"
    style={{
      backgroundImage: `url("${img}")`,
      backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center",
      filter: loaded ? "none" : "blur(20px)",
      transition: "filter 0.5s",
    }}>
    <img src={img} alt="" onLoad={handleLoad} style={{ display: "none" }} />
    {loaded}

    <div className="flex flex-col items-center pt-24">
            <a className="font-bold text-white text-3xl" href="/">
                Dreams Gist
            </a>
      <p className="text-white">Interprétations détaillées de rêves.</p>
      <p className="text-white">Horoscopes journaliers et hebdomadaires</p>
    </div>


    <div className="md:container md:mx-auto justify-center flex justify-center items-center px-auto" >
      <div className="flex flex-col justify-center items-start row-start-2 sm:row-start-1 py-12 px-6">
        <br /><br /><br />
        <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-white leading-normal">
        <br />  <strong></strong>
        </h1><br /><br />
        <p className="text-xl text-white mt-4 mb-6">
          <br /> <br /><br />
        </p>  
        </div>
          <div className="flex w-full">
            <div className="h-full w-full" >
            </div>
          </div>
    </div>
    <div className="flex flex-col items-center py-6 ">
            <p className="text-lg text-base text-white text-xl font-semibold italic">
                <span className="text-orange-400">
                  <a href="/reves/a" className="btn btn-primary btn m-1">Rêves </a> </span> & 
                 <span className="text-sky-600"> <a href="/horoscope/fr" className="btn btn-secondary btn m-1">Horoscope</a> </span>
            </p>

    </div>
    <div className="py-6">

    </div>
 </div>
);
}













function Home () {
  return(
    <>
    <TopAppBarFr></TopAppBarFr>

            <LazyBackgroundImageKnowledge img={engineer}></LazyBackgroundImageKnowledge>

        <HomeFooterFr></HomeFooterFr>
    </>
  )
}
export default Home;