import React from "react";

export default function HomeFooterFr() {
  return (
    <footer className="relative   pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >

      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="px-4">
            <h2><a href="/a-propos" className="block uppercase text-black-500 text-sm font-bold mb-2">
              A propos
            </a></h2>
            {/*<a href="/disclaimer" className="block uppercase text-white text-sm font-bold mb-2">
              Disclaimer
            </a>*/}
            </div>
            <br /><br />

          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-black-500 text-sm font-bold mb-2">
                  Liens Utiles
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="https://facilemath.com/" target="_blank" rel="noreferrer">Facile Math 
                    </a>
                  </li>
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="https://alleramusic.com/" target="_blank" rel="noreferrer">All Era Music
                    </a>
                  </li>
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="https://hightechleaks.com/" target="_blank" rel="noreferrer">High-Tech Leaks
                    </a>
                  </li>
                  <li>
                    <a className="text-black-500  block pb-2 text-sm"
                        href="https://glamoroustrend.com/" target="_blank" rel="noreferrer">Glamorous Trend
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-grey-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full  px-4 mx-auto text-center">
            <div className="text-sm text-black-500  py-1">
              Copyright © {new Date().getFullYear()}{" "}Dreams Gist par{" "}
              <a
                href="https://arfusoft.com/"
                className="text-black-500 hover:text-orange-900"
              >
                Arfusoft
              </a>.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
