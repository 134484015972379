import {React, useState, useEffect} from "react";
import { useParams } from 'react-router-dom';


function DreamViewText ({id, word, interpretation}){
    return (
        <>
        <section className="text-gray-600 body-font">
    <div className="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
      <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
        <h1 className="title-font sm:text-2xl text-xl mb-4 font-medium text-blue-900">{word}
          
        </h1>
        <p className="mb-8 leading-relaxed text-black-500 dark:text-white ">{interpretation}</p>
        <div className="flex justify-center">
        </div>
      </div>
    </div>
  </section><hr className="mx-12 md:mx-24" />
        </>
    )
}


function DreamViewImage ({id, word, interpretation, image}){
        return (
            <>
            <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-2xl text-xl mb-4 font-medium text-blue-900">{word}
              
            </h1>
            <p className="mb-8 leading-relaxed text-black-500 dark:text-white ">{interpretation}</p>
            <div className="flex justify-center">
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img className="object-cover object-center rounded" alt="hero" src={image}/>
          </div>
        </div>
      </section><hr className="mx-12 md:mx-24" />
            </>
        )
    }

  export  {DreamViewText,DreamViewImage};